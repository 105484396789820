$(function(){

  if($(window).width() > 980 && $('.inner-content-body').length > 0){
    new PerfectScrollbar('.inner-content-body', { suppressScrollX: true, });
  } 

  $('.btn-more').on("click",function() {

    var height = $('.id-arrow-down').innerHeight();

    var percentageToScroll = 100;
    var height = $('.id-arrow-down').innerHeight();
    var scrollAmount = height * percentageToScroll / 100;

    $(".inner-content-body").animate({scrollTop: scrollAmount}, 1000);
    
    $(this).remove();
  }); 
  

});