$(function() {
  
  // scrollFunction();

  // Side Menu Control
  var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left' );
  var mySlidebars = new $.slidebars();
  $('.mobile-menu-btn').on('click', function() {
      mySlidebars.slidebars.toggle(myDir);
  });

  $('.children-open').on('mouseenter',function(){
    setTimeout(function(ele){
      if($(ele).is(":hover")){
        $(ele).find('ul').slideDown();
      }
    },300,this);
  });
  $('.children-open').on('mouseleave',function(){
    $(this).find('ul').slideUp();
  });

  if($(window).width() > 980){
    new PerfectScrollbar('.main-navi', { suppressScrollX: true, });
  } 
  

  // $(window).on("scroll",scrollFunction);

  // function scrollFunction() {
  //   if ($(window).scrollTop() > 20) {
  //     $(".main-header").addClass("scrolling");
  //   } else {
  //     $(".main-header").removeClass("scrolling");
  //   }
  // }
});
